import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  questionEachPage: [
    {
      question: 'What is the birth date of the main driver?',
      error:
        'For drivers in this age range, we calculate a tailor-made premium. Please contact us at info@verzekerje.be',
      type: 'date',
      value: null,
      isCheck: false,
      isError: false,
    },
    {
      question:
        'Does the main driver have had their permanent driving licence for a period longer than three years?',
      error:
        'We’re sorry, a valid and permanent driver’s licence issued more than three years ago is required.',
      type: 'boolean',
      value: null,
      isCheck: false,
      isError: false,
    },
    {
      question:
        'Over the last three years, did you incur no more than one accident as a driver where you caused the accident? (moto of auto)',
      error:
        'We’re sorry, we can only offer you an insurance if you have had no more than one accident during the last three years.',
      type: 'boolean',
      value: null,
      isCheck: false,
      isError: false,
    },
    {
      question:
        'Is your motorcycle younger than two years old? Or do you have active omnium motorcycle insurance?',
      error: 'We’re sorry, we can only insure a motorcycle that is younger than 2 years old',
      type: 'boolean',
      value: null,
      isCheck: false,
      isError: false,
    },
    {
      question: 'Choose what is applicable for you',
      type: 'radio',
      value: null,
      isCheck: 0,
      isError: false,
      listQuestion: [
        {
          content:
            'Your bike does not have an R in the model designation and is rated at less than 185hp or 136kW.',
          isActive: false,
          value: 'Non-electrical',
        },
        {
          content:
            'Your bike does have an R in the model designation and is rated at more than 185hp or 136kW.',
          isActive: false,
          value: 'Non-electrical',
        },
        {
          content: 'You have an electrical bike.',
          isActive: false,
          value: 'Electrical',
        },
      ],
    },
    {
      question:
        'What is the catalog value of your motorcycle (excl. VAT and incl. options and accessories such as GPS or suitcases)?',
      type: 'input',
      isError: false,
      isCheck: false,
    },
    {
      question: 'Please choose the type of motorcycle you want to insure:',
      type: 'radio',
      value: null,
      isCheck: false,
      isError: false,
      listQuestion: [
        {
          content: 'BMW, Triumph or Harley-Davidson',
          isActive: false,
          value: 'BMW, Triumph or Harley-Davidson',
        },
        {
          content: 'Quad, cross motorcycle or enduro',
          isActive: false,
          value: 'error',
        },
        {
          content: 'All other types',
          isActive: false,
          value: 'Other',
        },
      ],
    },
  ],
  questionOnePage: [
    {
      questionId: 1,
      question: 'Do you want to insure your equipment such as helmet or driving suit ?',
      type: 'radio',
      listQuestion: [
        {
          content: 'No',
          isActive: false,
          value: '0',
        },
        {
          content: 'up to €1.000',
          isActive: false,
          value: 100000,
        },
        {
          content: 'up to €2.000',
          isActive: false,
          value: 200000,
        },
      ],
    },
    {
      questionId: 2,
      question: 'Choose what coverage in BA + Full omnium',
      type: 'boolean',
      listQuestion: [
        {
          content: '12 months',
          isActive: false,
          value: 12,
        },
        {
          content: '24 months',
          isActive: false,
          value: 24,
        },
        {
          content: '36 months',
          isActive: false,
          value: 36,
        },
      ],
    },
    {
      questionId: 4,
      question: 'Would you like to insure possible legal assistance?',
      type: 'boolean',
      listQuestion: [
        {
          content: 'Yes',
          isActive: false,
          value: 'yes',
        },
        {
          content: 'No',
          isActive: false,
          value: 'no',
        },
      ],
    },
    {
      questionId: 5,
      question: 'Would you like to enjoy roadside assistance for your bike?',
      type: 'boolean',
      listQuestion: [
        {
          content: 'Yes',
          isActive: false,
          value: 'yes',
        },
        {
          content: 'No',
          isActive: false,
          value: 'no',
        },
      ],
    },
  ],
  countQuestionOnePage: 0,
};

const newQuestionSegal = {
  question: 'Please choose the type of motorcycle you want to insure:',
  type: 'radio',
  value: null,
  isCheck: false,
  isError: false,
  listQuestion: [
    {
      content: 'BMW or Harley-Davidson',
      isActive: false,
      value: 'BMW, Triumph or Harley-Davidson',
    },
    {
      content: 'Quad, cross motorcycle or enduro',
      isActive: false,
      value: 'error',
    },
    {
      content: 'All other types',
      isActive: false,
      value: 'Other',
    },
  ],
};

const questionSlice = createSlice({
  name: 'question',
  initialState,
  reducers: {
    updateQuestionEachPage(state, action) {
      if (action.payload[4].isCheck === 0) {
        const newQuestion = action.payload.filter(question => question.isCheck !== true);
        state.questionEachPage = newQuestion;
      } else if (action.payload[4].isCheck === 1 && action.payload.length === 6) {
        const newQuestion = action.payload.filter(question => question.isCheck !== true);
        state.questionEachPage = [...newQuestion, newQuestionSegal];
      } else if (
        (action.payload[4].isCheck === 1 || action.payload[4].isCheck === 0) &&
        action.payload.length === 7
      ) {
        const newQuestion = action.payload.filter(question => question.isCheck !== true);
        state.questionEachPage = newQuestion;
      }
    },
    incrementQuestionOnePage(state, action) {
      state.countQuestionOnePage = action.payload;
    },
    updateQuestionOnePage(state, action) {
      state.questionOnePage = action.payload;
    },
    resetDataQuestions: () => initialState,
  },
});

export const {
  updateQuestionOnePage,
  incrementQuestionOnePage,
  updateQuestionEachPage,
  resetDataQuestions,
} = questionSlice.actions;
export default questionSlice.reducer;
