import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  option: {
    riskModel: {
      optionsDriverSafety: true,
      optionsMiniOmnium: false,
      optionsFullOmnium: true,
      optionsRoadsideAssistance: false,
      optionsLegalAssistance: false,
      optionsExtendedAssistance: false,
      mainDriver: {
        name: '',
        address: {
          country: '',
          street: '',
          number: '',
          location: '',
          postalCode: '',
        },
        email: '',
        phone: '',
        mobile: '',
      },
      mainDriverBirthDate: '',
      mainDriverPreviousDamages: 'none',
      motorcycleSegment: '',
      motorcycleCc: '125 cc or more',
      motorcycleModel: '',
      motorcycleNumber: 1,
      motorcycleDepreciationInMonths: '',
      equipmentValue: 0,
      mainDriverDriversLicenceType: 'Permanent',
      mainDriverDriversLicenceDate: '',
      mainDriverDriversLicenceNumber: '',
      motorcycleDateFirstRegistration: '',
      motorcycleBrand: '',
      motorcycleType: '',
      motorcycleChassisNumber: '',
      motorcycleLicencePlate: '',
      motorcyclePower: '',
      motorcyclePrice: {
        value: 0,
        decimals: 2,
        symbol: '€',
      },
      motorcycleAntiTheft: '',
      physicalDamageType: 'Mini 1',
      useDueCare: true,
      useRental: false,
      paymentFrequency: 'Yearly',
      version: 1,
    },
    ratingOption: {
      underwriterCommissionPercentage: 1500,
      underwriterReductionPercentage: 0,
      brokerCommissionPercentage: 0,
      brokerReductionPercentage: 0,
      taxPercentage: 2675,
    },
    pricingTable: {
      maximumBase: 100000000,
      click: false,
      factor: 500,
      scale: 100000,
    },
  },
  totalPremium: {
    totalPremium: 0,
    rateMethods: [
      {
        rateMethodName: 'civilLiabitity',
        rateMethodPremium: 0,
        required: 1,
        sort: 1,
      },
      {
        rateMethodName: 'fullOmnium',
        rateMethodPremium: 0,
        required: 1,
        sort: 0,
      },
      {
        rateMethodName: 'legalAssistance',
        rateMethodPremium: 0,
        required: 0,
        sort: 2,
      },
      {
        rateMethodName: 'roadsideAssistance',
        rateMethodPremium: 0,
        required: 0,
        sort: 3,
      },
      {
        rateMethodName: 'driverEquipment',
        rateMethodPremium: 0,
        required: 0,
        sort: 4,
      },
    ],
    paymentFrequency: '',
  },
  url: '',
};
const caculationPriceMethod = {
  civilLiabitity: {
    url: 'civilLiabitity',
    ratingOption: {
      underwriterCommissionPercentage: 1500,
      underwriterReductionPercentage: 0,
      brokerCommissionPercentage: 1000,
      brokerReductionPercentage: 0,
      taxPercentage: 2710,
    },
  },
  fullOmnium: {
    url: 'fullOmnium',
    ratingOption: {
      underwriterCommissionPercentage: 1500,
      underwriterReductionPercentage: 0,
      brokerCommissionPercentage: 1000,
      brokerReductionPercentage: 0,
      taxPercentage: 2675,
    },
  },
  legalAssistance: {
    url: 'legalAssistance',
    ratingOption: {
      underwriterCommissionPercentage: 1900,
      underwriterReductionPercentage: 0,
      brokerCommissionPercentage: 1400,
      brokerReductionPercentage: 0,
      taxPercentage: 1675,
    },
  },
  driverEquipment: {
    url: 'driverEquipment',
    ratingOption: {
      underwriterCommissionPercentage: 1500,
      underwriterReductionPercentage: 0,
      brokerCommissionPercentage: 1000,
      brokerReductionPercentage: 0,
      taxPercentage: 1675,
    },
  },
  roadsideAssistance: {
    url: 'roadsideAssistance',
    ratingOption: {
      underwriterCommissionPercentage: 1500,
      underwriterReductionPercentage: 0,
      brokerCommissionPercentage: 1000,
      brokerReductionPercentage: 0,
      taxPercentage: 1675,
    },
  },
};

const policyData = createSlice({
  name: 'policyData',
  initialState,
  reducers: {
    getDataFromQuestionsPolicy(state, actions) {
      const payload = actions.payload;
      const newState = { ...state.option.riskModel, ...payload };
      state.option.riskModel = newState;
      return state;
    },
    getDataFromEmailPolicy(state, action) {
      state.option.riskModel = JSON.parse(action.payload.riskModel);
      state.totalPremium.totalPremium = action.payload.premium * 100;
      state.totalPremium.rateMethods = action.payload.ratePackage;
    },
    getDataTotalPremium(state, action) {
      state.totalPremium = action.payload;
    },
    handleOptionsLegalAssistance(state, action) {
      //LegalAssistance
      const filterOptionRateMethod = state.totalPremium.rateMethods.filter(
        option => option.rateMethodName !== caculationPriceMethod.legalAssistance.url,
      );
      const option = {
        rateMethodName: caculationPriceMethod.legalAssistance.url,
        rateMethodPremium: action.payload,
        required: 0,
        sort: 2,
      };
      filterOptionRateMethod.push(option);
      state.totalPremium.rateMethods = filterOptionRateMethod;
    },
    changeOptionsLegalAssistance(state, action) {
      state.option.riskModel.optionsLegalAssistance = action.payload;
      state.option.ratingOption = caculationPriceMethod.legalAssistance.ratingOption;
      state.url = caculationPriceMethod.legalAssistance.url;
    },

    handleOptionsRoadsideAssistance(state, action) {
      //RoadsideAssistance
      const filterOptionRateMethod = state.totalPremium.rateMethods.filter(
        option => option.rateMethodName !== caculationPriceMethod.roadsideAssistance.url,
      );
      const option = {
        rateMethodName: caculationPriceMethod.roadsideAssistance.url,
        rateMethodPremium: action.payload,
        required: 0,
        sort: 3,
      };
      filterOptionRateMethod.push(option);
      state.totalPremium.rateMethods = filterOptionRateMethod;
    },
    changeOptionsRoadsideAssistance(state, action) {
      state.option.riskModel.optionsRoadsideAssistance = action.payload;
      state.option.ratingOption = caculationPriceMethod.roadsideAssistance.ratingOption;
      state.url = caculationPriceMethod.roadsideAssistance.url;
    },
    handleOptionsDriverEquipment(state, action) {
      // DriverEquipment
      const filterOptionRateMethod = state.totalPremium.rateMethods.filter(
        option => option.rateMethodName !== caculationPriceMethod.driverEquipment.url,
      );
      const option = {
        rateMethodName: caculationPriceMethod.driverEquipment.url,
        rateMethodPremium: action.payload,
        required: 0,
        sort: 4,
      };
      filterOptionRateMethod.push(option);
      state.totalPremium.rateMethods = filterOptionRateMethod;
    },
    changeOptionsDriverEquipment(state, action) {
      state.option.riskModel.equipmentValue = Number(action.payload);
      state.option.ratingOption = caculationPriceMethod.driverEquipment.ratingOption;
      state.url = caculationPriceMethod.driverEquipment.url;
    },
    changeOptionsExtendedAssistance(state, action) {
      state.option.riskModel.optionsExtendedAssistance = action.payload;
    },

    changeOptionPaymentFrequency(state, action) {
      state.option.riskModel.paymentFrequency = action.payload;
    },
    onChangeInsuredValueOfPolicy(state, action) {
      state.option.riskModel.motorcyclePrice.value = action.payload;
      state.option.ratingOption = caculationPriceMethod.fullOmnium.ratingOption;
    },
    updateInsuredValue(state, action) {
      let fullOmnium = {
        rateMethodName: 'fullOmnium',
        rateMethodPremium: action.payload,
        required: 1,
        sort: 0,
      };
      let newState = state;
      let rateMethods = newState.totalPremium.rateMethods;
      const totalOmnium = rateMethods.filter(item => item.sort != 0);
      totalOmnium.push(fullOmnium);
      state.totalPremium.rateMethods = totalOmnium;
      let sum =
        totalOmnium.reduce((a, b) => ({
          rateMethodPremium: Number(a.rateMethodPremium) + Number(b.rateMethodPremium),
        })).rateMethodPremium / 100;

      state.totalPremium.totalPremium = sum;
    },
    onChangeValueBirthday(state, action) {
      state.option.riskModel.mainDriverBirthDate = action.payload;
      state.option.ratingOption = caculationPriceMethod.civilLiabitity.ratingOption;
    },
    updateBirthdayValue(state, action) {
      let civilLiabitity = {
        rateMethodName: 'civilLiability',
        rateMethodPremium: action.payload,
        required: 1,
        sort: 1,
      };
      let newState = state;
      let rateMethods = newState.totalPremium.rateMethods;
      const totalCivilLiabitity = rateMethods.filter(item => item.sort != 1);
      totalCivilLiabitity.push(civilLiabitity);
      state.totalPremium.rateMethods = totalCivilLiabitity;
      let sum =
        totalCivilLiabitity.reduce((a, b) => ({
          rateMethodPremium: Number(a.rateMethodPremium) + Number(b.rateMethodPremium),
        })).rateMethodPremium / 100;

      state.totalPremium.totalPremium = sum;
    },
    resetPolicyData: () => initialState,
  },
});

export const {
  getDataFromQuestionsPolicy,
  getDataFromEmailPolicy,
  getDataTotalPremium,
  handleOptionsLegalAssistance,
  handleOptionsRoadsideAssistance,
  handleOptionsDriverEquipment,
  changeOptionsLegalAssistance,
  changeOptionsRoadsideAssistance,
  changeOptionsDriverEquipment,
  changeOptionPaymentFrequency,
  changeOptionsExtendedAssistance,
  onChangeInsuredValueOfPolicy,
  onChangeValueBirthday,
  updateBirthdayValue,
  updateInsuredValue,
  resetPolicyData,
} = policyData.actions;
export default policyData.reducer;
