import auth from '@modules/auth/locales/en.json';
import faq from '@modules/home/pages/home/locales/en/FAQ.json';
import carefree from '@modules/home/pages/home/locales/en/carefree.json';
import extensiveCoverage from '@modules/home/pages/home/locales/en/extensiveCoverage.json';
import implementationProcess from '@modules/home/pages/home/locales/en/implementationProcess.json';
import reportDamage from '@modules/home/pages/home/locales/en/reportDamage.json';
import banner from '@modules/home/pages/home/locales/en/banner.json';
import header from '@modules/app/components/locales/en/header.json';
import footer from '@modules/app/components/locales/en/footer.json';
import publicQuestion from '@modules/home/pages/question/locales/en/publicQuestion.json';
import questionOnePage from '@modules/home/pages/question/locales/en/questionOnePage.json';
import mainDriverInformation from '@modules/home/pages/mainDriverInformation/locales/en/mainDriverInformation';
import questionEachPage from '@modules/home/pages/question/locales/en/questionEachPage.json';
import motorcycleData from '@modules/purchase/locales/en/motorcycleData.json';
import policyData from '@modules/purchase/locales/en/policyData.json';
import driverInsuredParty from '@modules/purchase/locales/en/driverInsuredParty.en.json';
import sendEmail from '@modules/home/pages/question/locales/en/sendEmail.en.json';
import coverages from '@modules/widget/locales/en/coveragesWidget.json';
const locales = {
  auth: auth,
  faq: faq,
  carefree: carefree,
  extensiveCoverage: extensiveCoverage,
  implementationProcess: implementationProcess,
  reportDamage: reportDamage,
  banner: banner,
  header: header,
  footer: footer,
  publicQuestion: publicQuestion,
  questionOnePage: questionOnePage,
  mainDriverInformation: mainDriverInformation,
  questionEachPage: questionEachPage,
  motorcycleData: motorcycleData,
  policyData: policyData,
  driverInsuredParty: driverInsuredParty,
  sendEmail: sendEmail,
  coverages: coverages,
};

export default locales;
