import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from '@services/http';

export const widgetApi = createApi({
  reducerPath: 'widgetApi',
  baseQuery: axiosBaseQuery(),
  endpoints: build => {
    return {
      getWidgetData: build.query({
        query: parameter => ({
          url: `api/v1/widget/riskModel?url=${parameter?.url}&token=${parameter?.token}&language=${parameter?.language}`,
          method: 'get',
        }),
      }),
      createWidgetLead: build.mutation({
        query: ({ token, url, language, bodyWidget }) => ({
          url: `/api/v1/widget/riskModel?token=${token}&url=${url}&language=${language}`,
          method: 'patch',
          body: bodyWidget,
        }),
      }),
    };
  },
});

export const { useGetWidgetDataQuery, useCreateWidgetLeadMutation } = widgetApi;
