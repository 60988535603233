import { createSlice } from '@reduxjs/toolkit';
const initialState = {
  formErrorMessage: {
    mainDriverFormErrors: { error: true },
    personFormErrors: { error: true },
    companyFormErrors: { error: true },
    motorcycleFormErrors: { error: true },
  },
};

const formErrorMessage = createSlice({
  name: 'formErrorMessage',
  initialState,
  reducers: {
    getMessageFormMainDriverForm(state, action) {
      state.formErrorMessage.mainDriverFormErrors = action.payload;
    },
    getMessageFormpersonForm(state, action) {
      state.formErrorMessage.personFormErrors = action.payload;
    },
    getMessageFormCompanyForm(state, action) {
      state.formErrorMessage.companyFormErrors = action.payload;
    },
    getMessageFormmotorcycleFormErrors(state, action) {
      state.formErrorMessage.motorcycleFormErrors = action.payload;
    },
    resetFormErrorMessage: () => initialState,
  },
});

export const {
  getMessageFormMainDriverForm,
  getMessageFormpersonForm,
  getMessageFormCompanyForm,
  getMessageFormmotorcycleFormErrors,
  resetFormErrorMessage,
} = formErrorMessage.actions;
export default formErrorMessage.reducer;
