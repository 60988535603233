//import libs
import React, { Suspense } from 'react';
import { Route, Redirect } from 'react-router-dom';

//import components
import NoneOverall from '@layout/noneOverall';
import Loading from '../utils/Loading/Loading';
const NoneOverallRoutes = ({ component: Component, isAuthenticated, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props => {
        return (
          <Suspense fallback={<Loading visible={true} />}>
            {!isAuthenticated ? (
              <NoneOverall>
                <Component {...props} />
              </NoneOverall>
            ) : (
              <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
            )}
          </Suspense>
        );
      }}
    />
  );
};
export default NoneOverallRoutes;
