import { createSlice } from '@reduxjs/toolkit';
import { dateToISO8601Format } from '../../../../utils/converter/dateToISO8601Format';

const initialState = {
  optionsRoadsideAssistance: true,
  optionsLegalAssistance: true,
  optionsExtendedAssistance: true,
  mainDriver: {
    name: '',
    firstName: '',
    lastName: '',
    title: '',
    address: {
      country: '',
      street: '',
      number: '',
      suffix: '',
      location: '',
      postalCode: '',
    },
    email: '',
    mobile: '',
    phone: '',
  },
  mainDriverBirthDate: '',
  mainDriverPreviousDamages: '',
  motorcycleSegment: '',
  motorcycleCc: '',
  motorcycleModel: '',
  motorcycleNumber: '',
  motorcyclePower: '',
  motorcycleLicencePlate: '',
  motorcycleChassisNumber: '',
  motorcycleDepreciationInMonths: '12',
  equipmentValue: 0,
  mainDriverDriversLicenceType: '',
  mainDriverDriversLicenceDate: '',
  mainDriverDriversLicenceNumber: '',
  motorcycleDateFirstRegistration: '',
  motorcycleBrand: '',
  motorcycleType: '',
  motorcyclePrice: {
    value: 10,
    decimals: 2,
    symbol: '€',
  },
  motorcycleAntiTheft: '',
  physicalDamageType: 'Mini 1',
  motorcycleBuildYear: '',
  motorcycleFuelType: '',
  motorcycleUsage: '',
  startDateOfPolicy: '',
  useDueCare: true,
  useRental: false,
  paymentFrequency: '',
  optionsDriverSafety: true,
  optionsMiniOmnium: true,
  optionsFullOmnium: true,
  version: 1,
};
const mainDriverInit = {
  name: '',
  firstName: '',
  lastName: '',
  title: '',
  address: {
    country: '',
    street: '',
    number: '',
    suffix: '',
    location: '',
    postalCode: '',
  },
  email: '',
  mobile: '',
};

const widget = createSlice({
  name: 'widget',
  initialState,
  reducers: {
    updateCoverages(state, actions) {
      state.useDueCare = actions.payload.isUseDueCare;
      state.useRental = actions.payload.isUseRental;
      state.optionsLegalAssistance = actions.payload.isLegalAssistance;
      state.optionsRoadsideAssistance = actions.payload.isRoadside;
      state.optionsExtendedAssistance = actions.payload.isEquipmentValue;
      state.physicalDamageType = actions.payload.physicalDamage;
      state.equipmentValue = actions.payload.isEquipmentValue
        ? Number(actions.payload.equipmentValue) === 0
          ? 100000
          : Number(actions.payload.equipmentValue)
        : Number(actions.payload.equipmentValue);
      state.paymentFrequency = actions.payload.paymentFrequency;
      state.motorcycleDepreciationInMonths = Number(actions.payload.motorcycleDepreciationInMonths);
      state.startDateOfPolicy = dateToISO8601Format(actions.payload.startDateOfPolicy);
    },
    updateDriverData(state, actions) {
      if (state.mainDriver === undefined) {
        state.mainDriver = mainDriverInit;
      }
      state.mainDriverBirthDate = dateToISO8601Format(actions.payload?.dateOfBirth);
      state.mainDriver.name = actions.payload?.firstName + ' ' + actions.payload?.name;
      state.mainDriver.lastName = actions.payload?.name;
      state.mainDriver.firstName = actions.payload?.firstName;
      state.mainDriver.email = actions.payload?.email;
      state.mainDriver.address.street = actions.payload?.street;
      state.mainDriver.address.number = actions.payload?.houseNumber;
      state.mainDriver.address.location = actions.payload?.city;
      state.mainDriver.address.suffix = actions.payload?.suffix;
      state.mainDriver.address.postalCode = actions.payload?.postalCode;
      state.mainDriverDriversLicenceNumber = actions.payload?.drivingLicenceNumber;
      state.mainDriverDriversLicenceDate = actions.payload?.drivingLicenceSince
        ? dateToISO8601Format(actions.payload?.drivingLicenceSince)
        : '';
      state.mainDriverPreviousDamages = actions.payload?.numberOfAccident;
      state.mainDriver.address.country = actions.payload?.country;
      state.mainDriver.mobile = actions.payload?.mobile;
      state.mainDriver.phone = actions.payload?.phone;
      state.motorcycleNumber = Number(actions.payload?.motorcycleNumber);
      state.mainDriverDriversLicenceType = actions.payload?.drivingLicenceType;
      state.mainDriver.title = actions.payload.title;
    },
    updateMotorcycleData(state, actions) {
      state.motorcycleBrand = actions.payload.brand;
      state.motorcycleModel = actions.payload.model;
      state.motorcycleBuildYear = Number(actions.payload.yearOfConstruction);
      state.motorcycleFuelType = actions.payload.fuelType;
      state.motorcycleChassisNumber = actions.payload.chassisNumber;
      state.motorcycleLicencePlate = actions.payload.licencePlate;
      state.motorcyclePower = Number(actions.payload.power);
      state.motorcycleDateFirstRegistration = dateToISO8601Format(actions.payload.dateOfFirstUse);
      state.motorcyclePrice.value = Number(actions.payload.insuredValue) * 100;
      state.motorcycleUsage = actions.payload.motorcycleUsage;
      state.motorcycleCc = actions.payload.cc;
      state.motorcycleType = actions.payload.type;
      state.motorcycleSegment = actions.payload.segment;
      state.motorcycleAntiTheft = actions.payload.antitheft;
    },
    updateCoutryWidget(state, actions) {
      state.mainDriver.address.country = actions.payload;
    },
    updateWidgetModel(state, actions) {
      return actions.payload;
    },
  },
});

export const {
  updateCoverages,
  updateDriverData,
  updateMotorcycleData,
  updateCoutryWidget,
  updateWidgetModel,
} = widget.actions;
export default widget.reducer;
