import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  listAnswer: [
    {
      id: 1,
      answer: '',
    },
    {
      id: 2,
      answer: '',
    },
    {
      id: 3,
      answer: '',
    },
    {
      id: 4,
      answer: '',
    },
    {
      id: 5,
      answer: '',
    },
    {
      id: 6,
      answer: '',
    },
    {
      id: 7,
      answer: '',
    },
    {
      id: 8,
      answer: '',
    },
    {
      id: 9,
      answer: '',
    },
    {
      id: 10,
      answer: '',
    },
    {
      id: 11,
      answer: '',
    },
  ],
};

const answerQuestionsEmail = createSlice({
  name: 'answerQuestionsEmail',
  initialState,
  reducers: {
    updateAnswerQuestions(state, action) {
      state.listAnswer = action.payload;
    },
  },
});

export const { updateAnswerQuestions } = answerQuestionsEmail.actions;
export default answerQuestionsEmail.reducer;
