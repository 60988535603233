function hasNumber(myString) {
  return /\d/.test(myString);
}
export const CitizenNumberToString = citizenNumber => {
  if (citizenNumber) {
    let citizenNumberArr = citizenNumber.split('');
    let numberArr = citizenNumberArr.filter(item => {
      return hasNumber(item);
    });
    let citizenNumberString = numberArr.join('');
    return citizenNumberString;
  } else {
    return '';
  }
};
