// import lib
import { lazy } from 'react';
const routers = [
  {
    path: '/',
    exact: true,
    component: lazy(() => import('./pages/home')),
  },
  {
    path: '/private-demo',
    exact: true,
    auth: false,
    component: lazy(() => import('./pages/privateDemo')),
  },
];
const noneOverallRoutesHome = [
  {
    path: '/questions',
    exact: true,
    auth: false,
    component: lazy(() => import('./pages/question/index')),
  },
  {
    path: '/main-driver-information',
    exact: true,
    auth: false,
    component: lazy(() => import('./pages/mainDriverInformation/mainDriverInformation')),
  },
];
export { noneOverallRoutesHome };

export default routers;
