import { useTranslation } from 'react-i18next';
import logoWhite from '@assets/img/logo-icon-white.png';
import { BsFacebook } from 'react-icons/bs';
import { GrInstagram } from 'react-icons/gr';
import { BsLinkedin } from 'react-icons/bs';
function Footer() {
  const { t } = useTranslation();
  return (
    <footer className="w-full bg-black py-8">
      <div className="w-full flex flex-col items-center m-auto h-64 justify-center border-b-[1px] border-[#2D2D2D]">
        <div className="text-gray-400 px-[1rem]">{t('footer.question')}</div>
        <a className="text-white" href="mailto:info@verzekerje.be">
          info@verzekerje.be
        </a>
      </div>
      <div className="flex flex-row justify-around text-gray-400 w-full m-auto py-[3.125rem] w-10/12 m-auto">
        <div className="flex flex-col justify-around">
          <div className="flex flex-col w-full md:flex-row">
            <a
              className="text-secondary text-[#748A82] uppercase p-[1rem] hover:text-white cursor-pointer"
              href="https://verzekerje.be/privacy"
            >
              {t('footer.privatePolicy')}
            </a>
            <a
              className="text-secondary text-[#748A82] uppercase p-[1rem] hover:text-white cursor-pointer"
              href="https://verzekerje.be/algemene-voorwaarden"
            >
              {t('footer.termsAndConditions')}
            </a>
            <a
              className="text-secondary text-[#748A82] uppercase p-[1rem] hover:text-white cursor-pointer"
              href="https://verzekerje.be/over-ons"
            >
              {t('footer.aboutUs')}
            </a>
          </div>
          <div className="flex flex-col md:flex-row">
            <a
              className="text-secondary text-[#748A82] uppercase p-[1rem] hover:text-white cursor-pointer"
              href="https://verzekerje.be/downloads/"
            >
              {t('footer.downloads')}
            </a>
            <a
              className="text-secondary text-[#748A82] uppercase p-[1rem] hover:text-white cursor-pointer"
              href="https://verzekerje.be/contact"
            >
              {t('footer.contactUs')}
            </a>
            <a
              className="text-secondary text-[#748A82] uppercase p-[1rem] hover:text-white cursor-pointer"
              href="https://partner.verzekerje.be/login"
            >
              {t('footer.partnerDashboard')}
            </a>
          </div>
        </div>
        <a className="w-[20%]" href="https://rethinkit.verzekerje.be/">
          <img src={logoWhite} className="mx-auto w-[2rem] h-[2rem]" />
        </a>
        <div className="flex flex-col md:flex-row md:min-w-[27rem] items-center justify-center">
          <a
            className="rounded-full w-[3.75rem] h-[3.75rem] hover:bg-[#444444] p-[1.375rem]"
            href="https://www.facebook.com/verzekerjex/"
          >
            <BsFacebook className="text-[1rem]" />
          </a>
          <a
            className="rounded-full w-[3.75rem] h-[3.75rem] hover:bg-[#444444] p-[1.375rem]"
            href="https://www.instagram.com/verzekerje/"
          >
            <GrInstagram className="text-[1rem]" />
          </a>
          <a
            className="rounded-full w-[3.75rem] h-[3.75rem] hover:bg-[#444444] p-[1.375rem]"
            href="https://www.linkedin.com/company/verzeker-je/"
          >
            <BsLinkedin className="text-[1rem]" />
          </a>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
