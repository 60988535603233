import React from 'react';
import PropTypes from 'prop-types';

const containerStyle = {
  // paddingTop: '3.5rem',
};

const displayName = 'None Overall Layout';
const propTypes = {
  children: PropTypes.node.isRequired,
};
const NoneOverall = ({ children }) => {
  return (
    <>
      <div style={containerStyle}>
        <main>{children}</main>
      </div>
    </>
  );
};
NoneOverall.dispatch = displayName;
NoneOverall.propTypes = propTypes;

export default NoneOverall;
