//import libs
import React, { Suspense } from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';

//import components
import PublicLayout from '@layout/public';
import Loading from '../utils/Loading/Loading';

const PublicRoutes = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props => {
        return (
          <Suspense fallback={<Loading visible={true} />}>
            <PublicLayout>
              <Component {...props} />
            </PublicLayout>
          </Suspense>
        );
      }}
    />
  );
};

PublicRoutes.propTypes = {
  component: PropTypes.object.isRequired,
  location: PropTypes.object,
};

export default PublicRoutes;
