// import libs
import React from 'react';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectAuthToken } from '@modules/auth/stores/slices/auth';
// import components
import routes, { noneOverallRoutes } from '@routes';
import PrivateRoute from '@routes/private';
import PublicRoute from '@routes/public';
import NoneOverallRoutes from '@routes/noneOverall';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Toast from '../../utils/Toast/Toast';
function App() {
  const isLoggedIn = useSelector(selectAuthToken);
  return (
    <Router>
      <Switch>
        {routes.map((route, i) => {
          if (route.auth) {
            return <PrivateRoute isAuthenticated={!!isLoggedIn} key={i} {...route} />;
          }
          return <PublicRoute isAuthenticated={!!isLoggedIn} key={i} {...route} />;
        })}
        {noneOverallRoutes.map((route, i) => {
          return <NoneOverallRoutes isAuthenticated={!!isLoggedIn} key={i} {...route} />;
        })}
      </Switch>
      <Toast />
    </Router>
  );
}

export default App;
