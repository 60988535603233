import auth from '@modules/auth/locales/fr.json';
import faq from '@modules/home/pages/home/locales/fr/FAQ.json';
import carefree from '@modules/home/pages/home/locales/fr/carefree.json';
import extensiveCoverage from '@modules/home/pages/home/locales/fr/extensiveCoverage.json';
import implementationProcess from '@modules/home/pages/home/locales/fr/implementationProcess.json';
import reportDamage from '@modules/home/pages/home/locales/fr/reportDamage.json';
import banner from '@modules/home/pages/home/locales/fr/banner.json';
import header from '@modules/app/components/locales/fr/header.json';
import footer from '@modules/app/components/locales/fr/footer.json';
import publicQuestion from '@modules/home/pages/question/locales/fr/publicQuestion.json';
import questionOnePage from '@modules/home/pages/question/locales/fr/questionOnePage.json';
import mainDriverInformation from '@modules/home/pages/mainDriverInformation/locales/fr/mainDriverInformation';
import questionEachPage from '@modules/home/pages/question/locales/fr/questionEachPage.json';
import driverInsuredParty from '@modules/purchase/locales/fr/driverInsuredParty.fr.json';
import motorcycleData from '@modules/purchase/locales/fr/motorcycleData.json';
import policyData from '@modules/purchase/locales/fr/policyData.json';
import sendEmail from '@modules/home/pages/question/locales/fr/sendEmail.fr.json';
import coverages from '@modules/widget/locales/fr/coveragesWidget.json';
const locales = {
  auth: auth,
  faq: faq,
  carefree: carefree,
  extensiveCoverage: extensiveCoverage,
  implementationProcess: implementationProcess,
  reportDamage: reportDamage,
  banner: banner,
  header: header,
  footer: footer,
  publicQuestion: publicQuestion,
  questionOnePage: questionOnePage,
  mainDriverInformation: mainDriverInformation,
  questionEachPage: questionEachPage,
  driverInsuredParty: driverInsuredParty,
  sendEmail: sendEmail,
  motorcycleData: motorcycleData,
  policyData: policyData,
  coverages: coverages,
};

export default locales;
