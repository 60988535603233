import { authApi } from '@modules/auth/stores/services/auth';
import auth from '@modules/auth/stores/slices/auth';
import counter from '@modules/home/stores/slices/counter';
import question from '@modules/home/stores/slices/question';
import caculationPrice from '@modules/home/stores/slices/caculationPrice';
import leadGeneration from '../modules/purchase/store/slices/leadGeneration';
import policyData from '../modules/purchase/store/slices/policyData';
import personObjectOfCLead from '../modules/purchase/store/slices/personObjectOfCLead';
import figmaObjectOfLead from '../modules/purchase/store/slices/figmaObjectOfLead';
import formErrorMessage from '../modules/purchase/store/slices/formErrorMessage';
import answerQuestionsEmail from '../modules/home/stores/slices/answerQuestionsEmail';
import widget from '../modules/widget/store/slices/widget.slice.js';
import { widgetApi } from '../modules/widget/store/services/widget.services';
const rootReducer = {
  auth,
  [authApi.reducerPath]: authApi.reducer,
  widget,
  [widgetApi.reducerPath]: widgetApi.reducer,
  counter,
  question,
  caculationPrice,
  answerQuestionsEmail,
  leadGeneration,
  policyData,
  personObjectOfCLead,
  figmaObjectOfLead,
  formErrorMessage,
};

export default rootReducer;
