import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  riskModel: {
    optionsDriverSafety: true,
    optionsMiniOmnium: false,
    optionsFullOmnium: true,
    optionsRoadsideAssistance: false,
    optionsLegalAssistance: false,
    optionsExtendedAssistance: false,
    mainDriver: {
      name: '',
      firstName: '',
      lastName: '',
      title: '',
      address: {
        country: '',
        street: '',
        number: '',
        location: '',
        postalCode: '',
        box: '',
      },
      email: '',
      phone: '',
      mobile: '',
    },
    citizenNumber: '',
    mainDriverBirthDate: '',
    mainDriverPreviousDamages: 'none',
    motorcycleSegment: '',
    motorcycleCc: '125 cc or more',
    motorcycleModel: '',
    motorcycleNumber: 1,
    motorcycleDepreciationInMonths: 0,
    equipmentValue: 0,
    mainDriverDriversLicenceType: 'Permanent',
    mainDriverDriversLicenceDate: '',
    motorcycleDateFirstRegistration: '',
    motorcycleBrand: '',
    motorcycleType: '',
    motorcyclePrice: {
      value: 0,
      decimals: 2,
      symbol: '€',
    },
    motorcycleAntiTheft: 'mechanical',
    physicalDamageType: 'Mini 1',
    useDueCare: true,
    useRental: false,
    paymentFrequency: 'Yearly',
    version: 1,
  },
};

const rateMethodSlice = createSlice({
  name: 'caculationPrice',
  initialState,
  reducers: {
    handleDayofBirth(state, action) {
      // 'yyyy/MM/ddd'
      //civilLiability
      state.riskModel.mainDriverBirthDate = action.payload?.toString();
    },
    handleMotocycleType(state, action) {
      //civilLiability
      state.riskModel.motorcycleType = action.payload;
    },
    handleMotorcyclePrice(state, action) {
      //fullOmnium
      state.riskModel.motorcyclePrice.value = action.payload * 100;
    },
    handleMotorcycleSegment(state, action) {
      //fullOmnium
      state.riskModel.motorcycleSegment = action.payload;
    },
    handleEquipmentValue(state, action) {
      //driver equiment
      if (action.payload === '0') {
        state.riskModel.equipmentValue = 0;
        state.riskModel.optionsExtendedAssistance = false;
      } else {
        state.riskModel.equipmentValue = action.payload;
        state.riskModel.optionsExtendedAssistance = true;
      }
    },
    handlePaymentFrequency(state, action) {
      state.riskModel.paymentFrequency = action.payload;
    },
    handleMotorcycleDepreciationInMonths(state, action) {
      //fullOmnium
      state.riskModel.motorcycleDepreciationInMonths = action.payload;
    },
    handleOptionsLegalAssistance(state, action) {
      //legalAssistance
      if (action.payload === 'yes') {
        state.riskModel.optionsLegalAssistance = true;
      } else {
        state.riskModel.optionsLegalAssistance = false;
      }
    },
    handleOptionsRoadsideAssistance(state, action) {
      //roadsideAssistance
      if (action.payload === 'yes') {
        state.riskModel.optionsRoadsideAssistance = true;
      } else {
        state.riskModel.optionsRoadsideAssistance = false;
      }
    },
    updateDataCalculation(state, action) {
      state.riskModel = action.payload;
    },
    resetDataCalculation: () => initialState,
  },
});

export const {
  handleDayofBirth,
  handleMotocycleType,
  handleMotorcyclePrice,
  handleMotorcycleSegment,
  handleEquipmentValue,
  handlePaymentFrequency,
  handleMotorcycleDepreciationInMonths,
  handleOptionsLegalAssistance,
  handleOptionsRoadsideAssistance,
  updateDataCalculation,
  resetDataCalculation,
} = rateMethodSlice.actions;
export default rateMethodSlice.reducer;
