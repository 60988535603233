import { lazy } from 'react';
const widgetRouters = [
  {
    path: '/widget',
    exact: false,
    auth: false,
    component: lazy(() => import('./widget')),
  },
];

export default widgetRouters;
