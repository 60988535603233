import { createSlice } from '@reduxjs/toolkit';
import { CitizenNumberToString } from '../../../../utils/converter/citizenNumberToString.converter';
function initialState() {
  let initialState = {
    countryId: 'be',
    name: '',
    entityType: 'privatePerson',
    startDate: '',
    nationalNumber: '',
    address: {
      postalCode: '',
      country: '',
      location: '',
      street: '',
      suffix: '',
      number: '',
    },
    language: 'en',
    contact: {
      email: '',
      address: {
        country: '',
        location: '',
        number: '',
        postalCode: '',
        street: '',
        suffix: '',
      },
      firstName: '',
      lastName: '',
      mobile: '',
      name: '',
      phone: '',
      title: 'Mr.',
    },
    firstName: '',
    lastName: '',
    type: '',
  };
  if (Object.keys(sessionStorage).includes('personObjectOfCLeadSession')) {
    const personObjectOfCLeadSession = JSON.parse(
      sessionStorage.getItem('personObjectOfCLeadSession'),
    );
    return personObjectOfCLeadSession;
  } else {
    return initialState;
  }
}
const personObjectOfCLead = createSlice({
  name: 'personObjectOfCLead',
  initialState,
  reducers: {
    getDataPersonForm(state, actions) {
      const payload = actions.payload;
      state.name = payload.firstName + ' ' + payload.name;
      state.address.postalCode = payload.postalCode;
      state.address.location = payload.city;
      state.address.street = payload.street;
      state.address.suffix = payload.suffix;
      state.contact.address.suffix = payload.suffix;
      state.contact.address.country = payload.nationality;
      state.address.country = payload.nationality;
      state.contact.address.location = payload.city;
      state.contact.address.number = payload.number;
      state.contact.address.postalCode = payload.postalCode;
      state.contact.address.street = payload.street;
      state.contact.firstName = payload.firstName;
      state.firstName = payload.firstName;
      state.lastName = payload.name;
      state.contact.lastName = payload.name;
      state.contact.name = payload.name;
      state.contact.address.suffix = payload.suffix;
      state.startDate = payload.dateOfBirth;
      state.nationalNumber = CitizenNumberToString(payload.citizenNumber);
      state.address.number = payload.number;
      state.contact.phone = payload.phone;
      state.contact.title = payload.title;
      state.type = payload.title;
    },
    setLanguageOfPerson(state, actions) {
      state.language = actions.payload;
    },
    setPersonFromSession(state, actions) {
      state = actions.payload;
    },
    setCountryIdPerson(state, actions) {
      state.countryId = actions.payload;
    },
    resetPersonObjectOfCLead: () => initialState,
  },
});

export const {
  getDataPersonForm,
  setLanguageOfPerson,
  setPersonFromSession,
  setCountryIdPerson,
  resetPersonObjectOfCLead,
} = personObjectOfCLead.actions;
export default personObjectOfCLead.reducer;
