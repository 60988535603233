import { createSlice } from '@reduxjs/toolkit';
// import { CitizenNumberToString } from '../../../../utils/converter/citizenNumberToString.converter';

function initialState() {
  let initialState = {
    countryId: '',
    language: '',
    riskModel: {
      optionsDriverSafety: false,
      optionsMiniOmnium: false,
      optionsFullOmnium: true,
      optionsRoadsideAssistance: true,
      optionsLegalAssistance: true,
      optionsExtendedAssistance: false,
      mainDriver: {
        name: '',
        firstName: '',
        lastName: '',
        title: '',
        address: {
          country: '',
          street: '',
          number: '',
          location: '',
          postalCode: '',
          suffix: '',
        },
        email: '',
        phone: '',
        mobile: '',
      },
      mainDriverBirthDate: '',
      mainDriverDriversLicenceType: 'Permanent',
      mainDriverDriversLicenceDate: '',
      mainDriverDriversLicenceNumber: '',
      mainDriverPreviousDamages: '',
      motorcycleSegment: '',
      motorcycleCc: '',
      motorcycleModel: '',
      motorcycleNumber: '',
      motorcycleDateFirstRegistration: '',
      motorcycleDepreciationInMonths: 12,
      motorcycleBrand: '',
      motorcycleType: '',
      motorcycleChassisNumber: '',
      motorcycleLicencePlate: '',
      motorcyclePower: '',
      motorcyclePrice: {
        value: 0,
        decimals: 2,
        symbol: '€',
      },
      motorcycleAntiTheft: '',
      motorcycleBuildYear: '',
      motorcycleFuelType: '',
      motorcycleUsage: '',
      startDateOfPolicy: '',
      equipmentValue: 0,
      physicalDamageType: '',
      useDueCare: true,
      useRental: false,
      paymentFrequency: '',
      version: 1,
    },
    comment: '-',
    privacyStatementAgreed: true,
    role: 'customer',
  };
  if (Object.keys(sessionStorage).includes('leadGenerationSession')) {
    const leadGenerationSession = JSON.parse(sessionStorage.getItem('leadGenerationSession'));
    return leadGenerationSession;
  } else {
    return initialState;
  }
}

const leadGeneration = createSlice({
  name: 'leadGeneration',
  initialState,
  reducers: {
    getDataFromQuestions(state, actions) {
      const payload = actions.payload;
      const newState = { ...state, ...payload };
      state = newState;
      return state;
    },
    getDataFromEmail(state, actions) {
      state.riskModel = actions.payload;
    },
    getDataMainDriver(state, actions) {
      state.riskModel.mainDriverBirthDate = actions.payload.dateOfBirth?.toString();
      state.riskModel.mainDriver.name = actions.payload.name + ' ' + actions.payload.firstName;
      state.riskModel.mainDriver.title = actions.payload.title;
      state.riskModel.mainDriver.firstName = actions.payload.firstName;
      state.riskModel.mainDriver.lastName = actions.payload.name;
      state.riskModel.mainDriver.email = actions.payload.email;
      state.riskModel.mainDriver.address.street = actions.payload.street;
      state.riskModel.mainDriver.address.number = actions.payload.number;
      state.riskModel.mainDriver.address.location = actions.payload.city;
      state.riskModel.mainDriver.address.postalCode = actions.payload.postalCode;
      state.riskModel.mainDriverDriversLicenceNumber = actions.payload.drivingLicenceNumber;
      state.riskModel.mainDriverDriversLicenceDate = actions.payload.drivingLicenceSince;
      // state.riskModel.citizenNumber = CitizenNumberToString(actions.payload.citizenNumber);
      state.riskModel.mainDriver.address.country = actions.payload.nationality;
      state.riskModel.mainDriver.profession = actions.payload.profession;
      state.riskModel.mainDriver.address.suffix = actions.payload.suffixs;
      state.riskModel.mainDriverPreviousDamages = actions.payload.numberOfAccident;
      state.riskModel.mainDriver.phone = actions.payload.phone;
      state.riskModel.mainDriver.mobile = actions.payload.mobile;
    },
    // eslint-disable-next-line
    getDataMotorcycle(state, actions) {
      state.riskModel.motorcycleBrand = actions.payload.brand;
      state.riskModel.motorcycleModel = actions.payload.model;
      state.riskModel.motorcycleBuildYear = Number(actions.payload.yearOfConstruction);
      state.riskModel.motorcycleFuelType = String(actions.payload.fuelType);
      state.riskModel.motorcycleUsage = actions.payload.usage;
      state.riskModel.startDateOfPolicy = new Date().toISOString().split('T')[0];
      state.riskModel.motorcycleChassisNumber = actions.payload.chassisNumber;
      state.riskModel.motorcycleLicencePlate = actions.payload.licencePlate;
      state.riskModel.motorcyclePower = Number(actions.payload.power);
      state.riskModel.motorcycleAntiTheft = actions.payload.motorcycleAntiTheft;
      state.riskModel.motorcycleDateFirstRegistration = actions.payload.dateOfFirstUse;
      state.riskModel.motorcyclePrice.value = parseFloat(actions.payload.insuredValue) * 100;
    },
    // eslint-disable-next-line
    getDataPolicyAndCoverage(state, actions) {
      state.riskModel.equipmentValue = actions.payload.equipmentValue;
      state.riskModel.motorcycleDepreciationInMonths =
        actions.payload.motorcycleDepreciationInMonths;
      state.riskModel.motorcyclePrice.value = actions.payload.motorcyclePrice.value;
      state.riskModel.optionsLegalAssistance = actions.payload.optionsLegalAssistance;
      state.riskModel.optionsRoadsideAssistance = actions.payload.optionsRoadsideAssistance;
      state.riskModel.paymentFrequency = actions.payload.paymentFrequency;
      state.riskModel.motorcycleCc = actions.payload.motorcycleCc;
      state.riskModel.physicalDamageType = actions.payload.physicalDamageType;
      state.riskModel.optionsExtendedAssistance = actions.payload.optionsExtendedAssistance;
    },
    addCustomerOrgIdToLeadGeneration(state, actions) {
      state = { ...state, customerOrgId: actions.payload };
    },
    addPersonObjectOfCLeadToLeadGeneration(state, actions) {
      state = actions.payload;
    },
    // eslint-disable-next-line
    setLanguageOfLead(state, actions) {
      state.language = actions.payload;
    },
    setCountryIdLeadGeneration(state, actions) {
      state.countryId = actions.payload;
    },
    updateDataLeadGeneration(state, actions) {
      state = actions.payload;
    },
    updatePaymentFrequency(state, actions) {
      state.riskModel.paymentFrequency = actions.payload;
    },
    resetLeadGeneration: () => initialState,
  },
});

export const {
  getDataFromQuestions,
  getDataFromEmail,
  getDataMainDriver,
  getDataMotorcycle,
  getDataPolicyAndCoverage,
  addCustomerOrgIdToLeadGeneration,
  addPersonObjectOfCLeadToLeadGeneration,
  setLanguageOfLead,
  updateDataLeadGeneration,
  updatePaymentFrequency,
  setCountryIdLeadGeneration,
  resetLeadGeneration,
} = leadGeneration.actions;
export default leadGeneration.reducer;
