import auth from '@modules/auth/locales/en.json';
import faq from '@modules/home/pages/home/locales/nl/FAQ.json';
import carefree from '@modules/home/pages/home/locales/nl/carefree.json';
import extensiveCoverage from '@modules/home/pages/home/locales/nl/extensiveCoverage.json';
import implementationProcess from '@modules/home/pages/home/locales/nl/implementationProcess.json';
import reportDamage from '@modules/home/pages/home/locales/nl/reportDamage.json';
import banner from '@modules/home/pages/home/locales/nl/banner.json';
import header from '@modules/app/components/locales/nl/header.json';
import footer from '@modules/app/components/locales/nl/footer.json';
import publicQuestion from '@modules/home/pages/question/locales/nl/publicQuestion.json';
import questionOnePage from '@modules/home/pages/question/locales/nl/questionOnePage.json';
import mainDriverInformation from '@modules/home/pages/mainDriverInformation/locales/nl/mainDriverInformation';
import questionEachPage from '@modules/home/pages/question/locales/nl/questionEachPage.json';
import motorcycleData from '@modules/purchase/locales/nl/motorcycleData.json';
import policyData from '@modules/purchase/locales/nl/policyData.json';
import driverInsuredParty from '@modules/purchase/locales/nl/driverInsuredParty.nl.json';
import sendEmail from '@modules/home/pages/question/locales/nl/sendEmail.nl.json';
import coverages from '@modules/widget/locales/nl/coveragesWidget.json';
const locales = {
  auth: auth,
  faq: faq,
  carefree: carefree,
  extensiveCoverage: extensiveCoverage,
  implementationProcess: implementationProcess,
  reportDamage: reportDamage,
  banner: banner,
  header: header,
  footer: footer,
  publicQuestion: publicQuestion,
  questionOnePage: questionOnePage,
  mainDriverInformation: mainDriverInformation,
  questionEachPage: questionEachPage,
  motorcycleData: motorcycleData,
  policyData: policyData,
  driverInsuredParty: driverInsuredParty,
  sendEmail: sendEmail,
  coverages: coverages,
};

export default locales;
