import { lazy } from 'react';
const routers = [
  {
    path: '/purchase',
    exact: true,
    auth: false,
    component: lazy(() => {
      return import('./purchase.js');
    }),
  },
];

export default routers;
