import { createSlice } from '@reduxjs/toolkit';

function initialState() {
  let initialState = {
    organisation: {
      countryId: 'be',
      name: '',
      number: '', //vatNumber
      entityType: 'legalPerson',
      address: {
        postalCode: '',
        country: '',
        location: '',
        street: '',
        suffix: '',
        number: '',
      },
      language: '',
      contact: {
        email: '',
        address: {
          country: '',
          location: '',
          number: '',
          postalCode: '',
          street: '',
          suffix: '',
        },
        mobile: '',
        name: '',
        phone: '',
      },
    },
  };
  if (Object.keys(sessionStorage).includes('figmaObjectOfLeadSession')) {
    const figmaObjectOfLeadSession = JSON.parse(sessionStorage.getItem('figmaObjectOfLeadSession'));
    return figmaObjectOfLeadSession;
  } else {
    return initialState;
  }
}
const figmaObjectOfLead = createSlice({
  name: 'figmaObjectOfLead',
  initialState,
  reducers: {
    getDataFimaForm(state, actions) {
      const payload = actions.payload;
      state.organisation.countryId = payload.vatNumber
        ? payload.vatNumber.slice(0, 2).toLowerCase()
        : '';
      state.organisation.number = payload?.vatNumber;
      state.organisation.address.postalCode = payload?.postalCode ? payload?.postalCode : '';
      state.organisation.address.country = payload?.vatNumber
        ? payload?.vatNumber.toLowerCase()
        : '';
      state.organisation.address.location = payload?.city ? payload?.city : '';
      state.organisation.address.street = payload?.street ? payload?.street : '';
      state.organisation.contact.email = payload?.email ? payload?.email : '';
      state.organisation.contact.address.country = payload?.vatNumber
        ? payload.vatNumber.slice(0, 2).toLowerCase()
        : '';
      state.organisation.contact.address.location = payload?.city ? payload?.city : '';
      state.organisation.contact.address.postalCode = payload?.postalCode
        ? payload?.postalCode
        : '';
      state.organisation.contact.address.street = payload?.street ? payload?.street : '';
      state.organisation.contact.firstName = payload?.firstName ? payload?.firstName : '';
      state.organisation.name = payload.name;
      state.organisation.contact.name = payload.name;
      state.organisation.contact.phone = payload.contact;
      state.organisation.contact.address.number = payload.number;
      state.organisation.address.number = payload.number;
      state.organisation.contact.address.suffix = payload.suffix;
      state.organisation.address.suffix = payload.suffix;
    },
    //eslint-disable-next-line
    setLanguageOfFigma(state, actions) {
      state.organisation.language = actions.payload;
    },
    setFigmaFromSession(state, actions) {
      state = actions.payload;
    },
    resetFigmaObjectOfLead: () => initialState,
  },
});

export const { getDataFimaForm, setLanguageOfFigma, setFigmaFromSession, resetFigmaObjectOfLead } =
  figmaObjectOfLead.actions;
export default figmaObjectOfLead.reducer;
