//import libs
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

//import components
import logoWhite from '@assets/img/logo-icon-white.png';
import logo from '@assets/img/logo-icon.png';
import './header.css';

function Header(props) {
  const { handleScrollClick, renderPurchaseNavbar } = props;
  const { i18n, t } = useTranslation();

  const [isBuger, setIsBuger] = React.useState(false);
  const handleChangeLanguage = lang => () => {
    i18n.changeLanguage(lang);
  };
  const handerClickBuger = () => {
    setIsBuger(!isBuger);
  };
  return (
    <div className="bg-primary p-[1rem] xl:p-0">
      <nav
        id="header"
        className={
          'w-full xl:h-20 z-10 top-0 bg-white xl:bg-primary border-b border-primarydark rounded-md p-[1rem] xl:p-0' +
          (isBuger ? ' h-[15.75rem]' : ' h-[4.65rem] overflow-hidden xl:overflow-visible')
        }
      >
        <div className=" w-10/12 xl:h-20  flex xl:flex-wrap mx-auto  xl:mt-0 xl:py-3 flex-col xl:flex-row  items-start xl:items-center">
          <div className="pl-4 px-[1rem] py-[0.375rem] p-0 flex flex-row w-full justify-between xl:hidden">
            <div className="xl:hidden">
              <a
                className="text-secondary text-base no-underline hover:no-underline text-sm text-[#151515] xl:text-secondary capitalize"
                href="/"
              >
                <img src={logo} className="w-8 text-white blocl xl:hidden"></img>
              </a>
            </div>
            <div onClick={handerClickBuger} className=" w-[3rem] h-[3rem] cursor-pointer">
              <div>
                <span className={'bugger xl:hidden' + (!isBuger ? '' : ' clicked')}>
                  <span></span>
                </span>
              </div>
            </div>
          </div>
          <div className="pl-4 hidden xl:block">
            <a
              className="text-secondary text-base no-underline hover:no-underline text-sm capitalize"
              href="/"
            >
              <img src={logoWhite} className="w-8 text-white"></img>
            </a>
          </div>
          {!renderPurchaseNavbar && (
            <>
              <div
                className={'pl-4 px-[1rem] py-[0.375rem]' + (isBuger ? ' nav-item' : '')}
                onClick={() => handleScrollClick('implementationProcessRef')}
              >
                <Link className="text-secondary text-base no-underline hover:no-underline text-sm text-[#151515] xl:text-secondary ">
                  {t('header.nameItem1')}
                </Link>
              </div>
              <div
                className={'pl-4 px-[1rem] py-[0.375rem]' + (isBuger ? ' nav-item' : '')}
                onClick={() => handleScrollClick('extensiveCoverageRef')}
              >
                <Link className="text-secondary text-base no-underline hover:no-underline text-sm text-[#151515] xl:text-secondary ">
                  {t('header.nameItem2')}
                </Link>
              </div>
              <div
                className={'pl-4 px-[1rem] py-[0.375rem]' + (isBuger ? ' nav-item' : '')}
                onClick={() => handleScrollClick('frequentlyAskedQuestionsRef')}
              >
                <Link className="text-secondary text-base no-underline hover:no-underline text-sm text-[#151515] xl:text-secondary ">
                  FAQ
                </Link>
              </div>
            </>
          )}
          {renderPurchaseNavbar && renderPurchaseNavbar()}
          <div
            className={
              'nav-item w-full flex-grow lg:flex lg:items-center w-auto  block mt-2 mt-0  bg-transparent z-20' +
              (isBuger ? ' nav-item' : '')
            }
            id="nav-content"
          >
            <ul className="list-reset flex justify-end flex-1 items-center">
              <div
                className={
                  'pl-4 px-[1rem] py-[0.375rem] cursor-pointer ' + (isBuger ? ' nav-item' : '')
                }
              >
                <a
                  className="text-secondary text-base no-underline hover:no-underline text-sm text-[#151515] xl:text-secondary capitalize"
                  href="https://mybroker.be/"
                >
                  {t('header.myBroker')}
                </a>
              </div>
              <li className="mr-3 group inline-block relative">
                <button className="bg-transparent text-[#151515] xl:text-secondary font-semibold py-2 px-4 rounded inline-flex items-center">
                  <span
                    className={
                      'mr-1 text-[#151515] xl:text-secondary ' + (isBuger ? ' nav-item' : '')
                    }
                  >
                    {t('header.lang')}
                  </span>
                  <svg
                    className={'fill-current h-4 w-4' + (isBuger ? ' nav-item' : '')}
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                  </svg>
                </button>
                <ul className="absolute hidden text-gray-700 pt-1 group-hover:block">
                  <li className="mb-[0.25rem] cursor-pointer">
                    <a
                      className="btn-lang rounded bg-gray-200 hover:bg-gray-200  block whitespace-no-wrap w-[4.5rem] px-[0.625rem] py-[0.357rem] text-center"
                      onClick={handleChangeLanguage('en')}
                    >
                      en
                    </a>
                  </li>
                  <li className="mb-[0.25rem] cursor-pointer">
                    <a
                      className="btn-lang rounded bg-gray-200 hover:bg-gray-200 block whitespace-no-wrap w-[4.5rem] px-[0.625rem] py-[0.357rem] text-center"
                      onClick={handleChangeLanguage('nl')}
                    >
                      nl
                    </a>
                  </li>
                  <li className="mb-[0.25rem] cursor-pointer">
                    <a
                      className="btn-lang rounded bg-gray-200 hover:bg-gray-200 block whitespace-no-wrap w-[4.5rem] px-[0.625rem] py-[0.357rem] text-center"
                      onClick={handleChangeLanguage('fr')}
                    >
                      fr
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
}

export default Header;
